import * as React from "react";
import Layout from "../../components/Layout";
import GeneralTab from "../../components/HTabs";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
// import Hero from "../../components/Hero";
import About from "../../components/About";
import Document from "../../components/Document";
import ImgContent from "../../components/GSTTemp/ImgContent";
import Service from "../../components/Services";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function PartnershipFirmRegistration() {
  // Banner slider
  // const FirmSlider = [

  //   {
  //     header_color: true,
  //     content_color: true,
  //     marginTop: 20,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83)`,
  //     header: `Online Partnership Company Registration Service`,
  //     content: `Best Partnership Company Online Registration Platform`,
  //     image: "/imgs/business/sliders/partnership-slider.png",
  //     alt_tag: "Best Online Partnership Company Registration in Hosur",
  //   },

  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Partnership Firm Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `12999`,
    f_price: `15999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };

  /* About Data Without Form*/
  const PfrAboutData = {
    title: `Partnership Company Registration`,
    sub_title: `Partnership Company Registration in just 7 Days`,
    paragraph: `In general, a partnership firm is one that is held by all of
  the partners who jointly run the business and share their
  obligations and liabilities in accordance with the terms and
  conditions outlined in the registered partnership deed.
  Partnership firms come in two basic varieties: registered
  and non-registered firms. While registration is not
  required, it is highly advised in order to take advantage of
  numerous government perks.`,
    header: `Our Features in Partnership Company Registration in India`,
    points: [`Agreement`, `Number of Partners`, `Lawful Business`],
  };

  /* Tabs Data */
  const PfrTabsData = {
    id: 'features',
    heading: 'Registration Procedure For Partnership Company Registration',
    paragraph: '',
    body: [
      {
        tab_title: 'Management',
        tab_content: [
          {
            content_title: 'Easiest Business Structure',
            content_paragraph: `One of the most straightforward business forms is a
          partnership firm, which may be formed by simply drafting a
          partnership document, which must be registered. As a
          result, it can be founded whenever the partners are
          prepared to contribute and with the least amount of
          paperwork, as opposed to other forms of corporations that
          need about 10-15 days to complete and so on.`,
            points: [],
          },
          {
            content_title: 'Easy Management',
            content_paragraph: `According to the partnership agreement, each partner of
          the partnership firm is given their own tasks and
          obligations based on their qualifications. The firm's
          partners can operate the business without interruption or
          controversy thanks to the partnership deed.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/decision-making.png',
        alt_tag: 'Partnership Company Management Decision Making',
      },

      {
        tab_title: 'Unlimited Liabilities',
        tab_content: [
          {
            content_title:
              'Partnership Firm Registration In India Unlimited Liabilities',
            content_paragraph: '',
            points: [
              `Collaborate on business endeavours.`,
              `Such a company's primary goal is to increase profits.`,
              `The partners in this partnership firm are those individuals.`,
              `profits and losses of the company are divided among all of
            the partners or employees in proportion to their
            individual ownership and contributions.`,
              `Amount of capital investment necessary, therefore the
            money committed is frequently substantial.`,
              `Partnership firm's decision-making process and procedure
            is a corporate or communal business.`,
              `Before making any decisions pertaining to the firm, each
            and every partner must travel in the same manner.`,
            ],
          },
        ],
        content_img: '/imgs/business/document-pvt.png',
        alt_tag:
          'TODAYFILINGS is The Best Partnership Company Registration Service Provider in Hosur',
      },
      {
        tab_title: 'Advantages',
        tab_content: [
          {
            content_title: 'Funds Raising',
            content_paragraph: `Funds can be easily raised through the registration of
          partnership firms in contrast to other firms or business
          structures like a proprietorship firm. Banks believe that
          this sort of company is better suited to approving credits
          and loans as well as splitting up the cost of paying the
          way to collect a more manageable contribution from the
          partners.`,
            points: [],
          },
          {
            content_title: 'Decision Making',
            content_paragraph: `Making a decision in a partnership firm registration in
          India is a simpler and easier process because there aren't
          any laws or regulations that must be followed. Without the
          approval of the other designated partners, one of the
          partners of the firm may conduct business or handle
          financial matters on the partnership firm's behalf.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/din.png',
        alt_tag: 'Partnership Company Registration Advantages',
      },
      {
        tab_title: 'Disadvantages',
        tab_content: [
          {
            content_title: 'Unlimited Liabilities',
            content_paragraph: `The major disadvantage for the firm's partners is that the
          partners' obligations are not restricted. The partners'
          own assets may be used to pay off loans, problems, or
          obligations in the event of any other disaster or
          difficulty. Yes, there is a cap on the maximum number of
          members; in the Partnership Firm, there is a cap of 20
          partners.`,
            points: [],
          },
          {
            content_title: 'Less Trustworthy',
            content_paragraph: `Because a partnership firm is simple to set up, can
          operate without having to register, and can operate
          without any particular laws or regulations, the general
          public has less faith in it.`,
            points: [],
          },
          {
            content_title: 'Abrupt Dissolution',
            content_paragraph: `partnership must be registered, and it can be readily
          dissolved in the event of insolvency or the death of any
          partner. Such circumstances prevent the business from
          expanding and developing.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/trustworthy.png',
        alt_tag: 'Partnership Company Registration Disadvantages',
      },
    ],
  };
  /* FaQ */
  var PartnershipComRegFAQ = [
    {
      header: 'Who can be the partners in a Partnership firm?',
      body: [
        {
          content: `Only those who reside in India are eligible to join a partnership
          firm as partners or members. Foreigners who wish to establish a
          business in India may select a Private Limited Company.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: `Is it possible to convert a partnership firm into a Private Limited
      company?`,
      body: [
        {
          content: `It is possible to change a partnership firm into a private limited
          company by submitting the required document to the appropriate
          authority.`,
          icon: false,
        },
      ],
    },
    {
      header: `What is the capital amount needed for the Partnership Firm
      Registration in India?`,
      body: [
        {
          content: `In India, there is no minimum capital need to register a partnership
          firm. The sum in the current bank account is all that is required.`,
          icon: false,
        },
      ],
    },
    {
      header: `Should a partnership firm be registered?`,
      body: [
        {
          content: `Not necessarily. However, unless a partnership firm is registered with the registrar
          of firms and societies, the rights of the partners inter se or against strangers cannot be enforced in
          a court of law. If the partnership deed itself creates, transfers or affects an interest in immovable property.
          `,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: 'How can you apply for the PAN card for the Partnership firm?',
      body: [
        {
          content: `You can apply for the PAN Card after the partnership agreement has
          been notarized. If you need help applying for a PAN for your
          partnership firm, you can use our support and direction.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Can a partnership firm be constituted for a particular business undertaking?',
      body: [
        {
          content: `Yes. A person may become a partner with another for a single adventure or undertaking.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: `Is it necessary for the partnership firms to submit an annual return
      to the Registrar of firms?`,
      body: [
        {
          content: `According to the Partnership Act of 1932, the Partnership Firm is
          not required to conduct an audit. However, it is required to have
          the account books audited if the company's annual revenue exceeds $2
          million.`,
          icon: false,
        },
      ],
    },
    {
      header: `What are the acts that all partners should give express consent to do?`,
      body: [
        {
          content: `Submitting a suit for arbitration, transfer of immovable property, acquisition
          of immovable property, withdrawal of suits is all forbidden except by the consent of all
           partners or by the usage of custom to the contrary.`,
          icon: false,
        },
      ],
    },
    {
      header: `Is the firm liable for the wrongful act of one partner?`,
      body: [
        {
          content: `Yes. The firm and all the partners are liable for the wrongful act or fraud which causes loss or injury to any third parties.`,
          icon: false,
        },
      ],
    },
    {
      header: `Should a retiring partner give notice of his retirement from the firm?`,
      body: [
        {
          content: `Every partner will be liable for the acts of the firm even if he has retired, if he has failed
          to give public notice of his retirement. Such notice should be given to the registrar of firms an by
          announcements in the local official gazette.`,
          icon: false,
        },
      ],
    },
  ];

  /* Document Section Data */
  const PfrDocData = {
    id: 'pfr-doc',
    heading: `Online Partnership Firm Registration`,
    sub_heading: `Documents Required for Partnership Firm Registration Online in
    India`,
    doc_contents: [
      {
        col: `col-lg-6 col-md-6`,
        delay: '100',
        img: `/imgs/icons/bregistration/partner.png`,
        alt_tag: `Partnership Deed`,
        header: `Partnership Deed`,
        description: `Partnership deed is an agreement created and prepared by
        the firm's partners that outlines all the policies,
        obligations, rules, procedures, methodologies, functions,
        and ownership stakes of the company.`,
      },
      {
        col: `col-lg-6 col-md-6`,
        delay: '200',
        img: `/imgs/icons/bregistration/aadhar.png`,
        alt_tag: `Pan Card`,
        header: `Identity Proof`,
        description: `The residence proof of the partners like PAN cards must be shown as identification documentation by
        every registered partnership firm Registration or member of
        a partnership firm Registration.`,
      },
      {
        col: `col-lg-6 col-md-6`,
        delay: '300',
        img: `/imgs/icons/bregistration/passport.png`,
        alt_tag: `Address Proof`,
        header: `Address Proof`,
        description: `Copy of each designated partner's identification document,
        such as their voter registration card, driver's license,
        ration card, or Aadhar card, must be presented for the partnership company registration.`,
      },
      {
        col: `col-lg-6 col-md-6`,
        delay: '400',
        img: `/imgs/icons/bregistration/electric.png`,
        alt_tag: `Office Address Proof`,
        header: `Office Address Proof`,
        description: `The applicant must provide the rent agreement along with a
        utility statement, such as an electricity, gas, water, or
        property tax bill, if the registered office location is a
        rented property.`,
      },
    ],
  };

  /* Img Content Component Data */
  const PfrIcData = {
    id: 'documents',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Procedure for Partnership Firm Registration in India',
    image: '/imgs/registration/dsc/dsc-doc.png',
    alt_tag: 'Online Partnership Company Registration Near Bangalore',
    Paragraph: `If a designated partner passes away or retires, certain
    procedures or circumstances would be followed Other articles
    created with the approval of all partners or members of the
    company.Submission of the partnership deed, which the firm's partners prepared,
    along with all the paperwork needed to register the
    partnership.The relevant authorities verify the documents after submission. The firm
    is given the registration certificate if everything complies
    with the act's requirements.`,
    points: [
      {
        head: '',
        content: `Choose a distinctive name for the partnership company, and
        register your partnership firm by submitting Form 1.`,
        icon: true,
      },
      {
        head: '',
        content: `Send the completed application to the state's registrar of firms
        where the business will be established. The application form
        must be filled out in the appropriate format and include the
        required fee.`,
        icon: true,
      },
      {
        head: '',
        content: `With the approval of all the partners or members of the company, a
        partnership deed is properly planned and prepared on stamp
        paper.`,
        icon: true,
      },
      {
        head: '',
        content: `The type of business and the activities that are involved and information
        about loans provided by the firm's partners`,
        icon: true,
      },
      {
        head: '',
        content: `Information regarding the capital investment made by each of the firm's
        partners shares, as well as each partner's interest`,
        icon: true,
      },
      {
        head: '',
        content: `Information on how the profit and loss ratio is distributed also Rules,
        regulations, rights, obligations, commissions, salaries, or
        other amounts payable among all of the firm's partners.`,
        icon: true,
      },
    ],
  };

  /* Service data */
  const PartnershipServiceComp = {
    header: `Types of Partners`,
    content: `Based on the level of liability in a partnership firm,
    the different types of partners can be determined.`,
    image: '/imgs/gifs/img2.gif',
    alt_img: 'Partnership',
    points: [
      `Active/Actual Partners`,
      `Sleeping or Dormant Partner`,
      `Nominal Partner`,
      `Partner in Profits Only`,
      `Sub Partners`,
      `Incoming/Outgoing Partner`,
    ],
  };

  return (
    <div>
      <Seo
        title='Partnership Firm Registration'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={FirmSlider} /> */}

          <Package packageData={PackageCompData} />

          <About about_data={PfrAboutData} />

          <Counter />

          <Service item={PartnershipServiceComp} />

          <Document docSecData={PfrDocData} />

          <ImgContent item={PfrIcData} />

          <GeneralTab HTabsData={PfrTabsData} />

          <Cta />

          <FAQAccord items={PartnershipComRegFAQ} />
        </main>
      </Layout>
    </div>
  );
}
